import "normalize.css";
import "../../styles/vars.less";
import "../../styles/reset.less";
import "../../vant"; // @H5.vant
import "../../styles/global.less";

import Vue from "vue";
import App from "./index.vue";
import { storageUtil } from "@/utils/index";

Vue.config.productionTip = false;

const browserHistory =
  storageUtil.getSession(storageUtil.keyMap.BROWSER_HISTORY) || [];
const href = window.location.href;
if (
  browserHistory.length === 0 ||
  browserHistory[browserHistory.length - 1] !== href
) {
  browserHistory.push(href);
}
storageUtil.setSession(storageUtil.keyMap.BROWSER_HISTORY, browserHistory);

new Vue({
  render: (h) => h(App),
}).$mount("#app");
