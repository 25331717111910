import { randomSort } from '../../../utils/index'

/**
 * 图谱数据源
 */
export const getGraphData = () => {
  const graphData = [
    {
      name: "免税商城",
      children: [
        {
          name: "商城汇总",
          type: 2,
          src: "https://mp.weixin.qq.com/s/ZYY93Vq_mAY5tZDsfUvZNg",
        },
        {
          name: "cdf海南免税官方商城",
          desc: "中免H5商城",
          style: "flex-basis: 50%;",
          src: "https://m.cdfgsanya.com/",
          type: 2,
        },
        {
          name: "海旅免税",
          desc: "海旅H5商城",
          style: "flex-basis: 50%;",
          src: "https://m.hltmsp.com",
          type: 2,
        },
        {
          name: "cdf海南免税",
          desc: "中免小程序商城",
          style: "flex-basis: 50%;",
          appId: "wxd8df280e2b1b5d13",
          originId: "gh_c54106bd20a1",
          type: 3,
        },
        {
          name: "CNSC离岛免税购",
          desc: "国免小程序商城",
          style: "flex-basis: 50%;",
          appId: "wx2e1651359d8af94a",
          originId: "gh_7c88d875e80e",
          type: 3,
        },
        {
          name: "GDF免税城",
          desc: "海控小程序商城",
          style: "flex-basis: 50%;",
          appId: "wx3c3f979f8f53200d",
          originId: "gh_575fc7e0c2a5",
          type: 3,
        },
        {
          name: "海旅免税城",
          desc: "海旅小程序商城",
          style: "flex-basis: 50%;",
          appId: "wx948a211d78ead777",
          originId: "gh_a9c6e4109d22",
          type: 3,
        },
        {
          name: "深免 x DFS丨离岛免税",
          desc: "深免小程序商城",
          style: "flex-basis: 50%;",
          appId: "wx9378eac484af8bd9",
          originId: "gh_b3d17c2f5b88",
          type: 3,
        },
      ],
    },
    {
      name: "补购商城",
      children: [
        {
          name: "cdf会员购海南",
          desc: "中免补购H5商城",
          style: "flex-basis: 50%;",
          src: "https://m.hndfbg.com/",
          type: 2,
        },
        {
          name: "CNSC会员购",
          desc: "国免补购小程序商城",
          style: "flex-basis: 50%;",
          appId: "wx031ff3a2cb1fa88a",
          originId: "gh_1a47326e42ad",
          type: 3,
        },
        {
          name: "GDF会员购",
          desc: "海控补购小程序商城",
          style: "flex-basis: 50%;",
          appId: "wx4ba841a869b6aafd",
          originId: "gh_bfa82e1300dc",
          path: "pages/home/index?tx_cps_id=Eg9zVzVoMHVjOFZzRGd2cm4gASgB&sharer_id=139547910276&mid=8894",
          type: 3,
        },
        {
          name: "海旅免税会员购",
          desc: "海旅补购小程序商城",
          style: "flex-basis: 50%;",
          appId: "wx5834be43f36bcb8c",
          originId: "gh_efd6efd828b7",
          path: "pages/tabs/home?scene=sid%3D109322",
          type: 3,
        },
        {
          name: "深免集团会员购",
          desc: "深免补购小程序商城",
          style: "flex-basis: 50%;",
          appId: "wx31e0f1ba83603563",
          originId: "gh_af7b80c96535",
          path: 'pages/common/blank-page/index?scene=583879548',
          type: 3,
        },
      ],
    },
    {
      name: "常用查询",
      children: [
        {
          name: "额度查询",
          type: 2,
          mobileSrc: "http://www.singlewindow.hn.cn/hnsw-cdf-mobilequery/#/",
          src:
            "http://www.singlewindow.hn.cn/hnsw-cdf-queryserver/static/pages/hn/query/amountQuery.html",
        },
        {
          name: "物流查询",
          type: 2,
          src: "https://onecode-h5.digitalhainan.com.cn/pub/post-office/query",
        },
        {
          name: "常用公众号",
          type: 2,
          src: "https://mp.weixin.qq.com/s/StXlKGroBxeXnE7mlKnYBg",
        },
        {
          name: "常用小程序",
          type: 1,
          src: "./miniprogram.html",
        }
      ],
    },
    {
      name: "苹果手机",
      children: [
        {
          name: "琼版苹果手机介绍",
          type: 2,
          src: "https://mp.weixin.qq.com/s/Vdz3nY4-lDqTYq3qjUa6sw",
        },
        {
          name: "琼版苹果手机库存价格",
          type: 2,
          src: "./iphone.html",
        }
      ]
    },
    {
      name: "购物指南",
      children: [
        {
          name: "免税商品",
          type: 2,
          src: "https://mp.weixin.qq.com/s/BdzcFb4kgTZRZH-uSMqRUw",
        },
        {
          name: "免税布局",
          type: 2,
          src: "https://mp.weixin.qq.com/s/sJ0qMOprK0iBVkcdOjzE1Q",
        },
        {
          name: "购物须知",
          type: 2,
          src: "https://mp.weixin.qq.com/s/fCDCZg243Xfyo97UddwKVQ",
        },
        {
          name: "购物流程",
          type: 2,
          src: "https://mp.weixin.qq.com/s/wcUM0lCG5LWp6kx_lLaSig",
        },
        {
          name: "购买时间",
          type: 2,
          src: "https://mp.weixin.qq.com/s/iyKXul9AJyCdlBKuqUA3pQ",
        },
        {
          name: "购买条件",
          type: 2,
          src: "https://mp.weixin.qq.com/s/4opYvpyBHP6FnIKpEdFhuw",
        },
        {
          name: "提货说明",
          type: 2,
          src: "https://mp.weixin.qq.com/s/mSqQxeiCPlx12kwws3Sajw",
        },
        {
          name: "邮寄说明",
          type: 2,
          src: "https://mp.weixin.qq.com/s/fWsjbBPEfd_XlYshZdwyuQ",
        },
        {
          name: "返岛提取",
          type: 2,
          src: "https://mp.weixin.qq.com/s/aq9xcqSBv5_bBVBVAmU8tg",
        },
        {
          name: "发票开具说明",
          type: 2,
          src: "https://mp.weixin.qq.com/s/pyXEpGkly_-TjryxwvA5Uw",
        },
        {
          name: "异常核销",
          type: 2,
          src: "https://mp.weixin.qq.com/s/akssGy0u3PvgJf8ziLUqGA",
        },
        {
          name: "退货退款",
          type: 2,
          src: "https://mp.weixin.qq.com/s/OFZFGaWtKLPmMATpGBohFg",
        },
        {
          name: "省钱攻略",
          type: 2,
          src: "https://mp.weixin.qq.com/s/A3TnmKkaZ0k6OrIUSw7KnA",
        },
      ]
    },
    {
      name: "补购说明",
      children: [
        {
          name: "补购汇总",
          type: 2,
          src: "https://mp.weixin.qq.com/s/gjzk5dWdy0Qt8kJJPtXVtg",
        },
        {
          name: "中免补购",
          type: 2,
          src: "https://mp.weixin.qq.com/s/2QME4zDXhLh-OCgMkXx_VA",
        },
        {
          name: "国免补购",
          type: 2,
          src: "https://mp.weixin.qq.com/s/3iBxCb9sASSMMYE7YPD68Q",
        },
        {
          name: "海控补购",
          type: 2,
          src: "https://mp.weixin.qq.com/s/Yncb2vbrp6FWe-IK7jwbnw",
        },
        {
          name: "海旅补购",
          type: 2,
          src: "https://mp.weixin.qq.com/s/JAqNPTx0KpOf8dRJTO3cwg",
        },
        {
          name: "深免补购",
          type: 2,
          src: "https://mp.weixin.qq.com/s/01UD7jxJ0WpFNoNsJgz4zg",
        }
      ]
    },
    {
      name: "优惠活动",
      children: [
        {
          name: "最新活动",
          type: 1,
          style: "flex-basis: 50%;",
          src: "./activities.html",
        }
      ],
    },
    {
      name: "优惠券",
      children: [
        {
          name: "中免优惠券领取入口",
          desc: "免费领取600元优惠券",
          style: "flex-basis: 50%;",
          appId: "wxe8818457b58d21dd",
          originId: "gh_700b4d00cbf9",
          type: 3,
          path: "pages/index/index?scene=ZU32J7",
        },
        {
          name: "中免优惠券推广员入口",
          desc: "注册推广员，邀请码ZU32J7",
          style: "flex-basis: 50%;",
          appId: "wxe2ca776ae6142def",
          originId: "gh_156c9c2b3f7f",
          type: 3,
          path: "pages/login/login?scene=ZU32J7",
        },
        {
          name: "中免600元优惠券领取说明",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/QaJHeBQj3eud9PcDxMJ9Tw",
        },
        {
          name: "中免优惠券推广员说明",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/-kVzGEd6eWvDu2_3yDwEqA",
        },
      ],
    },
    {
      name: "免税门店",
      children: [
        {
          name: "门店汇总",
          type: 2,
          src: "https://mp.weixin.qq.com/s/SWSt52w2ASUcpGqhmQWwUw",
        },
        {
          name: "cdf海口日月广场免税店",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/W0W7nBCwpKCSQ8IBeAv9Yg",
        },
        {
          name: "cdf海口美兰机场T1航站楼免税店",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/FChYRMa2jgsgh2Pd-z1pdA",
        },
        {
          name: "cdf海口美兰机场T2航站楼免税店",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/sb3df4bazOLWzXh3hIfkiA",
        },
        {
          name: "海控GDF免税城",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/676XMNz9BBVfnbQ0re9rKw",
        },
        {
          name: "深免海口观澜湖免税店",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/PP2ZJIz24epDVsB40iplCw",
        },
        {
          name: "cdf三亚国际免税城",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/tFm2qoGHKYRf1bO1m3vT4g",
        },
        {
          name: "三亚海旅免税城",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/KVq7_0aRKq4KMM6R-rOtYg",
        },
        {
          name: "三亚国际免税购物公园",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/J5N3VwbwEcJp9OrMGFnPoA",
        },
        {
          name: "cdf三亚凤凰机场免税店",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/T4VxVrHQ2HExr0Kkwp0Hbw",
        },
        {
          name: "cdf琼海博鳌免税店",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/Efvm4DY2HUoJzqwxKUhOAg",
        },
        {
          name: "离岛免税体验店",
          type: 2,
          style: "flex-basis: 50%;",
          src: "https://mp.weixin.qq.com/s/paNdeh1TLHTbn0EZrrzgBQ",
        },
      ],
    },
    {
      name: "会员介绍",
      children: [
        {
          name: "会员汇总",
          type: 2,
          src: "https://mp.weixin.qq.com/s/tKyRJb0dyemoZePb-Qag2g",
        },
        {
          name: "中免会员",
          type: 2,
          src: "https://mp.weixin.qq.com/s/rwrjbt2Yl2LrETJfIk---A",
        },
        {
          name: "国免会员",
          type: 2,
          src: "https://mp.weixin.qq.com/s/cfF4Mn_a4I8Ql6fNBq7ZJA",
        },
        {
          name: "海控会员",
          type: 2,
          src: "https://mp.weixin.qq.com/s/Gp1xO7hN5o1KBd0T0N9-IQ",
        },
        {
          name: "海旅会员",
          type: 2,
          src: "https://mp.weixin.qq.com/s/-R56geWeTtJdWTYFt2vJKQ",
        },
        {
          name: "深免会员",
          type: 2,
          src: "https://mp.weixin.qq.com/s/kr6lcfIlpt0b0C6ySyNKBg",
        },
      ],
    },
    {
      name: "联系方式",
      children: [
        {
          name: "免税客服",
          type: 2,
          src: "https://mp.weixin.qq.com/s/ENpL_7YE1G8G-iDYPJNylw",
        },
      ]
    },
    {
      name: "常见问答",
      children: [
        {
          name: "常见问答",
          type: 2,
          src: "https://mp.weixin.qq.com/s/ie0LDYezpfqMtxqYzVuzwA",
        }
      ]
    },
    {
      name: "免税招聘",
      children: [
        {
          name: "来源途径",
          type: 2,
          src: "https://mp.weixin.qq.com/s/6AQXEaB-dmH_Y1oK335wrQ",
        },
        {
          name: "岗位招聘",
          type: 1,
          src: "./jobs.html",
        },
      ],
    },
    {
      name: "免税政策",
      children: [
        {
          name: "总体方案",
          type: 2,
          src: "https://mp.weixin.qq.com/s/tDKZGdKHGhOXx_xHoBwl3Q",
        },
        {
          name: "自贸港法",
          type: 2,
          src: "https://mp.weixin.qq.com/s/gXM1jDFu7ZhVRFw7XumNzg",
        },
        {
          name: "购物政策",
          type: 2,
          src: "https://mp.weixin.qq.com/s/e_rB7KGx499im53UbcIERQ",
        },
        {
          name: "商品品种",
          type: 2,
          src: "https://mp.weixin.qq.com/s/vWQS6Y6d68_fjc7qADvJ8A",
        },
        {
          name: "提货方式",
          type: 2,
          src: "https://mp.weixin.qq.com/s/8eLJ72HL8rLoMltqtD4GkQ",
        },
        {
          name: "监管办法",
          type: 2,
          src: "https://mp.weixin.qq.com/s/Ae-UCbsChI-0oBCvCmge_g",
        },
        {
          name: "溯源管理",
          type: 2,
          src: "https://mp.weixin.qq.com/s/qpt2yJLtVOm-K_BjQzznZw",
        },
        {
          name: "动态追踪",
          type: 2,
          src: "https://mp.weixin.qq.com/s/KeJNi991XjofQ_JGHOahQw",
        },
        {
          name: "大事记",
          type: 2,
          src: "https://mp.weixin.qq.com/s/DvAHvBDK9nr4q8w7dQUpyg",
        },
      ],
    },
    {
      name: "海南简介",
      children: [
        {
          name: "海南简介",
          type: 2,
          src: "https://mp.weixin.qq.com/s/caFiK_jqgdc74nsZujMv9A",
        },
        {
          name: "海南常识",
          type: 2,
          src: "https://mp.weixin.qq.com/s/-DqGy4NF_mMeAS9P9_q8EQ",
        },
        {
          name: "风景图片",
          type: 2,
          src: "https://mp.weixin.qq.com/s/8Xa6SaOEOpENZo-6tTb74A",
        },
      ],
    },
  ];
  graphData.forEach((item, index) => {
    item.id = index + 1;
    if (item.children) {
      item.children.forEach((subItem, subIndex) => {
        subItem.id = item.id + "" + (subIndex + 1);
        subItem.category = "";
      });
    }
  });
  randomSort(graphData);
  return graphData;
};

/**
 * 模糊查询大类
 * @param {*} name
 */
export const search = (name) => {
  return new Promise((resolve, reject) => {
    let result = [];
    let list = getGraphData().filter(item => {
      if (item.name.indexOf(name) >= 0) {
        return true;
      }
      if (item.children && item.children.length) {
        return item.children.some(child => {
          return child.name.indexOf(name) >= 0;
        });
      }
      return false
    });
    if (list && list.length > 0) {
      result = list || [];
    }
    if (result.length > 0) {
      resolve(result);
    } else {
      reject();
    }
  });
};

/**
 * 点击节点展开
 * @param {*} id
 */
export const expendNodes = (id) => {
  return new Promise((resolve, reject) => {
    let totalList = [];
    //拆除来所有的children到第一层
    getGraphData().forEach((item) => {
      getDeepChildrens(totalList, item, 0);
    });

    let list = [];
    for (let item of totalList) {
      if (item.parentId.toString() === id) {
        const { id, name } = item;
        list.push({
          id,
          name,
        });
      }
    }
    if (list.length > 0) {
      resolve(list);
    } else {
      reject();
    }
  });
};

export const getRandomColor = () => {
  return '#' + (55 + parseInt(Math.random() * 200)).toString(16)
    + (55 + parseInt(Math.random() * 200)).toString(16)
    + (55 + parseInt(Math.random() * 200)).toString(16);
};

/**
 * 递归数组,把所有children都拆出来到第一层
 * @param {*} list
 * @param {*} item
 */
function getDeepChildrens(list, item, parentId) {
  const { children, ...reset } = item;
  list.push({
    ...reset,
    parentId,
  });
  if (children && children.length > 0) {
    children.forEach((child) => {
      getDeepChildrens(list, child, item.id);
    });
  }
}
