import http from '@/services/http'
import { isJson, getRequestUrl } from '@/utils/index'
import wx from "weixin-js-sdk";
import { storageUtil } from '../utils';

/**
 * 获取文章列表
 * @param params
 * @param resolve
 * @param reject
 */
export const getIPhoneList = (params, resolve, reject) => {
  request('/mianshuiIPhone/getIPhone', 'get', params, resolve, reject)
}

/**
 * 获取文章列表
 * @param params
 * @param resolve
 * @param reject
 */
export const getActivityArticleList = (params, resolve, reject) => {
  request('/article/index', 'get', params, resolve, reject)
}

/**
 * 获取公众号文章详情
 * @param params
 * @param resolve
 * @param reject
 */
export const getArticleDetail = (params, resolve, reject) => {
  request('/article/detail', 'get', params, resolve, reject)
}

/**
 * 获取免税湖文章详情
 * @param params
 * @param resolve
 * @param reject
 */
export const getMshArticleDetail = (params, resolve, reject) => {
  request('/mshArticle/detail', 'get', params, resolve, reject)
}

/**
 * 获取免税招聘文章列表
 * @param params
 * @param resolve
 * @param reject
 */
export const getJobArticleList = (params, resolve, reject) => {
  request('/article/msJobIndex', 'get', params, resolve, reject)
}

/**
 * 获取微信签名
 * @param params
 * @param resolve
 * @param reject
 */
export const getWxSignPackage = (params, resolve, reject) => {
  request('/wxPublicAccount/index', 'get', params, resolve, reject);
}

/**
 * 获取天气查询
 * @param {*} params 
 * @param {*} resolve 
 * @param {*} reject 
 */
export const getWeather = (params, resolve, reject) => {
  request('/shoppingGuide/getWeather', 'get', params, resolve, reject);
}

export const getCoupon = (params, resolve, reject) => {
  request('/shoppingGuide/getCoupon', 'get', params, resolve, reject);
}

export const getHainanOfflineShop = () => {
  const shopCache = storageUtil.get(
    storageUtil.keyMap.OFFSHORE_OFFLINE_SHOP_LIST,
    10 * 60 * 1000
  );
  if (shopCache) {
    return new Promise((resolve) => {
      resolve(shopCache);
    });
  }
  return request({
    url:
      "/shoppingGuide/getHainanOfflineShop",
  });
};

function request(url, method, params, resolve, reject) {
  return http({
    methods: method,
    url: url,
    params,
  }).then(
    res => {
      if (res.status === 200) {
        if (isJson(res.data)) {
          resolve(res.data, res)
          return
        }
        reject && reject(res)
        return
      }
      reject && reject(res)
    },
    error => {
      reject && reject(error)
    },
  )
}

export const initWxConfig = () => {
  return new Promise((resolve, reject) => {
    const cachedConfig = storageUtil.getSession(storageUtil.keyMap.WX_CONFIG + '_' + getRequestUrl());
    if (cachedConfig) {
      cachedConfig.url = location.origin + '/' + getRequestUrl();
      wx.config({
        ...cachedConfig,
        ...{
          jsApiList: ["wx-open-launch-weapp"], // 必填，需要使用的JS接口列表
          openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
        },
      });
      wx.error(() => {
      });
      resolve(cachedConfig);
      return;
    }
    getWxSignPackage(
      { url: getRequestUrl() },
      (data) => {
        storageUtil.setSession(storageUtil.keyMap.WX_CONFIG + '_' + getRequestUrl(), data.data);
        wx.config({
          ...data.data,
          ...{
            jsApiList: ["wx-open-launch-weapp"], // 必填，需要使用的JS接口列表
            openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
          },
        });
        wx.error(() => {
        });
        resolve(data);
      },
      () => {
        reject("加载失败");
      }
    );
  });
};
