import _ from "lodash";
import axios from "axios";
import stringify from "qs/lib/stringify";
import dateFns_format from "date-fns/format";
import download from "./download";

export {
  download, // 附件下载
};

/**
 * 时间格式化
 * @param {string | number | Date} date
 * @param {string} [format] 官方文档：https://date-fns.org/v1.30.1/docs/format#description
 * @example dateFormat('YYYY年MM月DD日 HH时mm分ss秒SSS毫秒 Z时区 Q季度 X秒时间戳 x毫秒时间戳')
 */
export const dateFormat = function(date, format = "YYYY-MM-DD") {
  if (!date) return "";
  if (format === "@iso") format = "YYYY-MM-DDTHH:mm:ss.SSSZ";
  return dateFns_format(date, format);
};

/**
 * 将对象序列化成参数
 * @param {object} data
 * @param {Parameters<qs.stringify>[1]} [options]
 */
export const qsStringify = function(data, options) {
  options = { arrayFormat: "repeat", ...options };
  return stringify(data, options);
};

/**
 * 将对象转成 formData
 * @typedef {string | number | boolean | File | Blob} Val
 * @param {{[key: string]: Val | Val[]}} data
 * @param {'repeat' | 'brackets' | 'indices'} [arrayFormat]
 */
export const toFormData = function(data, arrayFormat = "repeat") {
  if (data instanceof FormData) return data;
  const formData = new FormData();
  _.each(data, (val, key) => {
    if (val === undefined) return;
    if (Array.isArray(val)) {
      val = val.filter((v) => v !== undefined);
      val.forEach((v, i) => {
        let k = key;
        if (arrayFormat === "brackets") k += "[]";
        else if (arrayFormat === "indices") k += `[${i}]`;
        formData.append(k, v === null ? "" : v);
      });
    } else {
      formData.append(key, val === null ? "" : val);
    }
  });
  return formData;
};

/**
 * 判断是否为 axios 取消的请求
 */
export const isCancel = axios.isCancel;

export const isJson = function(data) {
  if (typeof data == "string") {
    try {
      const obj = JSON.parse(data);
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return true;
};

/**
 * 删除左右两端的空格
 * @param {*} str
 */
export const trim = function(str) {
  return str.replace(/(^\s*)|(\s*$)/g, "");
};

/**
 * 判断是否为空白
 * @param {} data
 */
export const isBlank = function(data) {
  return data === null || data === undefined || data === "";
};

/**
 * 判断是不是在微信中
 * @returns {boolean}
 */
export const isWeiXin = () => {
  return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
};

/**
 * 判断是否为移动端
 */
export const isMobile = () => {
  return navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
};

/**
 * 校验数值型
 * @param data
 * @param options 附加选项，主要包含最大的小数位、范围等
 * @param options.digit 小数位
 * @param options.range 范围，如">=0"，最早提供这个参数是因为不知道怎么命名小于、大于、小于等于、大于等于的值，不推荐
 * @param options.min 最小值，给定值须大于等于最小值
 * @param options.max 最大值，给定值须小于等于最大值
 * @param options.lt 小于，给定值须小于该值
 * @param options.gt 大于，给定值须大于该值
 * @param options.le 小于等于，给定值须小于等于该值
 * @param options.gt 大于等于，给定值须大于等于该值
 * @param options.allowLastPoint 是否允许小数点在最后一位，允许是为了正在输入时候不要弹出警示
 * @returns {boolean}
 */
export function isNumber(data, options) {
  if (options && options.allowLastPoint) {
    // 当允许最后一位小数位时
    if (
      !data ||
      !data.toString().length ||
      data.toString().split(".").length - 1 > 1
    ) {
      // 当空字符或者多于1个小数点时直接返回
      return false;
    }
    if (data.toString().charAt(data.toString().length - 1) === ".") {
      data = data.toString().substr(0, data.toString().length - 1);
    }
  }
  const reg = /^[-+]?(0|[1-9]\d*)(\.\d+)?$/;
  let isValid = reg.test(data);
  if (!isValid) {
    return isValid;
  }
  if (!options) {
    return isValid;
  }
  // 当给定小数位时
  if (Object.prototype.hasOwnProperty.call(options, "digit")) {
    if (data.toString().indexOf(".") !== -1) {
      // 判断是否包含小数点，包含小数点的位数必须小于等于指定位数
      isValid =
        isValid &&
        data.toString().split(".")[1].length <= Number(options.digit);
    }
  }
  if (Object.prototype.hasOwnProperty.call(options, "min")) {
    isValid = isValid && data >= options.min;
  }
  if (Object.prototype.hasOwnProperty.call(options, "max")) {
    isValid = isValid && data <= options.max;
  }
  if (Object.prototype.hasOwnProperty.call(options, "lt")) {
    isValid = isValid && data < options.lt;
  }
  if (Object.prototype.hasOwnProperty.call(options, "gt")) {
    isValid = isValid && data > options.gt;
  }
  if (Object.prototype.hasOwnProperty.call(options, "le")) {
    isValid = isValid && data <= options.le;
  }
  if (Object.prototype.hasOwnProperty.call(options, "ge")) {
    isValid = isValid && data >= options.ge;
  }
  return isValid;
}

/**
 * 将一个未知类型的变量转为指定小数位的数字类型
 * @param number    需要格式化的数字，允许为undefined、null或字符串
 * @param options   转换选项
 * @param options.digit 格式化位数，值为-1时候不限制位数
 * @param options.cast 是否强制转换类型，默认转换
 * @returns {*}
 */
export function toNumber(number, options) {
  // 格式化位数
  const digit = options && options.digit !== undefined ? options.digit : 2;
  // 是否强制转换类型，默认转换
  const cast = options && options.cast !== undefined ? options.cast : true;
  if (number === false) {
      number = 0;
  } else if (number === true) {
      number = 1;
  }
  if (number === undefined || number === null || number === '' || isNaN(number)) {
      if (cast) {
          number = 0;
          if (digit === -1) {
              return parseFloat(number);
          }
          return parseFloat(number.toFixed(digit));
      }
      return number;
  }
  if (digit === -1) {
      return parseFloat(number);
  }
  return parseFloat(parseFloat(number).toFixed(digit));
}

/**
 * 复制JSON
 * @param {*} data
 */
export const copyJson = (data) => {
  if (isBlank(data)) {
    return data;
  }
  return JSON.parse(JSON.stringify(data));
};

/**
 * 获取请求路径url，除了主机外的地址
 */
export const getRequestUrl = () => {
  return location.href.substring(
    location.href.indexOf(location.host) + location.host.length + 1
  );
};

export const storageUtil = {
  keyMap: {
    BROWSER_HISTORY: "browser_history",
    INTELLIGENT_QA: "intelligent_qa",
    VISIT_FROM: "visit_from", // 访问来源，分普通网页和小程序weapp
    WX_CONFIG: "wx_config", // 微信配置
    OFFSHORE_OFFLINE_SHOP_LIST: "offshore_offline_shop_list",
  },
  getSession: (keyName) => {
    if (isBlank(keyName)) {
      return "";
    }
    let data = sessionStorage.getItem(keyName);
    if (isBlank(data)) {
      return data;
    }
    try {
      data = JSON.parse(data);
    } catch (e) {
      // eslint-disable-line
    }
    return data;
  },
  setSession: (keyName, value) => {
    sessionStorage.setItem(keyName, JSON.stringify(value));
  },
  getLocal: () => {
    // eslint-disable-line
  },
  setLocal: () => {
    // eslint-disable-line
  },
};

export const getUrlParams = function(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return "";
};

export const randomSort = function(array) {
  let length = array.length;

  if (!Array.isArray(array) || length <= 1) return;

  for (let index = 0; index < length - 1; index++) {
    let randomIndex = Math.floor(Math.random() * (length - index)) + index;
    [array[index], array[randomIndex]] = [array[randomIndex], array[index]];
  }

  return array;
};

export const datetimeBackgroundCanvas = {
  backgroundColor: "#000",
  particles: [],
  intervalId: null,
  canvasId: null,
  getBgColorByDateTime() {
    const whiteColor = "0x2196f3";
    const blackColor = "0x171e4a";
    const whiteColor16 = whiteColor.replace("0x", "");
    const whiteRed = parseInt("0x" + whiteColor16.substring(0, 2));
    const whiteGreen = parseInt("0x" + whiteColor16.substring(2, 4));
    const whiteBlue = parseInt("0x" + whiteColor16.substring(4, 6));
    const blackColor16 = blackColor.replace("0x", "");
    const blackRed = parseInt("0x" + blackColor16.substring(0, 2));
    const blackGreen = parseInt("0x" + blackColor16.substring(2, 4));
    const blackBlue = parseInt("0x" + blackColor16.substring(4, 6));
    let bgColor;
    let bgRed;
    let bgGreen;
    let bgBlue;

    // 早4点前和晚8点后认为是黑夜，中间简单认为12点是最亮的
    let currentDate = new Date();
    let currentTimestamp = currentDate.getTime();
    currentDate.setHours(4);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    let firstTimestamp = currentDate.getTime();
    currentDate.setHours(12);
    let middleTimestamp = currentDate.getTime();
    currentDate.setHours(20);
    let lastTimestamp = currentDate.getTime();
    // 早8点前和晚8点后认为是黑夜
    if (currentTimestamp > lastTimestamp || currentTimestamp < firstTimestamp) {
      bgColor = blackColor;
    } else if (currentTimestamp > middleTimestamp) {
      bgRed = Math.round(
        whiteRed +
          ((blackRed - whiteRed) * (currentTimestamp - middleTimestamp)) /
            (lastTimestamp - middleTimestamp)
      ).toString(16);
      bgGreen = Math.round(
        whiteGreen +
          ((blackGreen - whiteGreen) * (currentTimestamp - middleTimestamp)) /
            (lastTimestamp - middleTimestamp)
      ).toString(16);
      bgBlue = Math.round(
        whiteBlue +
          ((blackBlue - whiteBlue) * (currentTimestamp - middleTimestamp)) /
            (lastTimestamp - middleTimestamp)
      ).toString(16);
      bgColor =
        "0x" +
        this.fixColorChannel(bgRed) +
        this.fixColorChannel(bgGreen) +
        this.fixColorChannel(bgBlue);
    } else {
      bgRed = Math.round(
        blackRed +
          ((whiteRed - blackRed) * (currentTimestamp - firstTimestamp)) /
            (middleTimestamp - firstTimestamp)
      ).toString(16);
      bgGreen = Math.round(
        blackGreen +
          ((whiteGreen - blackGreen) * (currentTimestamp - firstTimestamp)) /
            (middleTimestamp - firstTimestamp)
      ).toString(16);
      bgBlue = Math.round(
        blackBlue +
          ((whiteBlue - blackBlue) * (currentTimestamp - firstTimestamp)) /
            (middleTimestamp - firstTimestamp)
      ).toString(16);
      bgColor =
        "0x" +
        this.fixColorChannel(bgRed) +
        this.fixColorChannel(bgGreen) +
        this.fixColorChannel(bgBlue);
    }
    return bgColor.replace("0x", "#");
  },
  fixColorChannel(color) {
    color = color.toString().replace("-", "");
    if (color.length < 2) {
      color = "0" + color;
    }
    return color;
  },
  initParticles(width, height) {
    const isNight = this.isNight();
    if (!isNight) {
      return;
    }
    this.particles = [];
    for (let i = 0; i < 10; i++) {
      this.particles.push({
        x: Math.random() * width,
        y: Math.random() * height,
        vx: (Math.random() - 0.5) * (Math.random() - 0.5) * 0.01,
        vy: (Math.random() - 0.5) * (Math.random() - 0.5) * 0.01,
        size: 0.5 + Math.random,
        color: this.getColorByDateTime(),
      });
    }
  },
  getColorByDateTime() {
    if (this.isNight()) {
      return "#" + Math.round(0xffff00 + 255 * Math.random()).toString(16);
    }
    // 白天时，只返回白色、灰色
    let redColor = this.fixColorChannel(
      Math.round(220 + 35 * Math.random()).toString(16)
    );
    let greenColor = this.fixColorChannel(
      Math.round(220 + 35 * Math.random()).toString(16)
    );
    let blueColor = this.fixColorChannel(
      Math.round(220 + 35 * Math.random()).toString(16)
    );
    return "#" + redColor + greenColor + blueColor;
  },
  drawParticles() {
    if (!this.canvasId) {
      return;
    }
    const canvas = document.getElementById(this.canvasId);
    const context = canvas.getContext("2d");
    let particle = null;
    for (let i = 0; i < this.particles.length; i++) {
      particle = this.particles[i];
      particle.x += particle.vx;
      particle.y += particle.vy;
      if (particle.x < 0) {
        particle.x = canvas.width;
      }
      if (particle.x > canvas.width) {
        particle.x = 0;
      }
      if (particle.y >= canvas.height) {
        particle.y = 0;
      }
      context.fillStyle = particle.color;
      context.beginPath();
      context.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      context.closePath();
      context.fill();
    }
  },
  initCanvas(id, width, height) {
    this.canvasId = id;
    const canvas = document.getElementById(id);
    canvas.width = width;
    canvas.height = height;
    this.initParticles();
  },
  isNight() {
    const hours = new Date().getHours();
    return hours < 6 || hours >= 18;
  },
  init(id, width, height) {
    this.backgroundColor = this.getBgColorByDateTime();
    this.initCanvas(id, width, height);
    this.destroy();
    this.intervalId = setInterval(() => {
      this.update();
    }, 1000);
  },
  update() {
    this.backgroundColor = this.getBgColorByDateTime();
    const canvas = document.getElementById(this.canvasId);
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = this.backgroundColor;
    context.fillRect(0, 0, canvas.width, canvas.height);
    this.drawParticles();
  },
  destroy() {
    clearInterval(this.intervalId);
  },
};