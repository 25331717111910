<template>
  <header class="header">
    <div class="center-wrapper">
      <button type="button" class="button-back" @click="goback">
        <van-icon name="arrow-left" class="icon-nav" />
      </button>
      <h1>{{ title }}</h1>
      <button type="button" class="button-nav" @click="toggleNav">
        <van-icon name="wap-nav" class="icon-nav" />
      </button>
      <div class="menu-nav" :class="menuVisible ? 'show' : ''">
        <div class="center-wrapper">
          <div class="menu-nav-bg" @click="toggleNav" />
          <ul>
            <li
              v-for="menuItem in menus"
              :key="menuItem.name"
              @click="changeNav(menuItem)"
            >
              {{ menuItem.name }}
            </li>
            <li class="nav-desc">
              <p class="nav-qrcode"><img src="@/assets/qrcode.jpg" /></p>
              <p class="nav-qrcode-desc">关注我们 了解更多</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'vant'
import { storageUtil } from '@/utils/index'

Vue.use(Icon)

export default {
  name: 'TopHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menuVisible: false,
      menus: [
        { name: '网站首页', src: './index.html' },
        { name: '网上商城', src: './onlineShop.html' },
        { name: '门店介绍', src: './offlineShop.html' },
        { name: '购物指南', src: './shoppingGuide.html' },
        { name: '活动优惠', src: './activities.html' },
        { name: '客服电话', src: './customerService.html' },
      ],
    }
  },
  methods: {
    goback() {
      const browserHistory =
        storageUtil.getSession(storageUtil.keyMap.BROWSER_HISTORY) || []
      if (browserHistory.length > 1) {
        window.history.go(-1)
      } else {
        window.location.href = './index.html'
      }
    },
    toggleNav() {
      this.menuVisible = !this.menuVisible
    },
    changeNav(data) {
      if (Object.prototype.hasOwnProperty.call(data, 'src')) {
        window.location.href = data.src
      }
    },
  },
}
</script>
