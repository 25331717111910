<template>
  <div class="page-wrapper">
    <div class="swiper-wrapper">
      <van-swipe class="banner" :autoplay="6000">
        <van-swipe-item @click="gotoCdfCoupon()">
          <img src="../../assets/banner/1.jpg" />
        </van-swipe-item>
        <van-swipe-item @click="gotoShenmianCoupon()">
          <img src="../../assets/banner/2.jpg" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <ul class="menu">
      <li
        class="menu-item"
        @click="openMenu(item)"
        v-for="(item, index) in menus"
        :key="index"
      >
        <div class="menu-icon" :class="item.iconClass"></div>
        <div class="menu-text">{{ item.name }}</div>
      </li>
    </ul>
    <header class="article-header">
      <h3>
        活动优惠<button
          class="article-more"
          type="button"
          @click="gotoActivities()"
        >
          更多>>
        </button>
      </h3>
    </header>
    <ul class="article-list">
      <li
        class="article-item"
        v-for="(item, index) in articles"
        :key="index"
        @click="openArticle(index)"
      >
        <div class="article-icon">
          <!--<img :src="item.cover" />-->
          <img v-lazy="item.cover" alt="" />
        </div>
        <div class="article-container">
          <div class="relative wp100">
            <div class="article-tag">{{ item.biz_tag }}</div>
            <div class="article-date">{{ item.update_time }}</div>
          </div>
          <div class="article-name">{{ item.title }}</div>
        </div>
      </li>
    </ul>
    <footer class="footer">
      <a href="https://beian.miit.gov.cn/">琼ICP备20001556号-2</a>
    </footer>
  </div>
</template>

<style lang="less" scoped>
.swiper-wrapper {
  position: relative;
  padding: 15px 18px 0;
}

.banner {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 2px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 2px;
  }
}

.menu {
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: space-evenly;
  flex-flow: row wrap;
  padding: 10px 10px;
}

.menu-item {
  width: 72px;
  padding: 5px 0;
  text-align: center;
  flex: 0 0 25%;
}

.menu-item:active {
  background-color: #eee;
}

.menu-icon {
  width: 55px;
  height: 55px;
  margin: 0 auto;
  background-size: 55px 55px;
}

.menu-text {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}

.icon-online-shop {
  background-image: url("../../assets/menu/onlineShop.svg");
}
.icon-offline-shop {
  background-image: url("../../assets/menu/offlineShop.svg");
}
.icon-shopping-guide {
  background-image: url("../../assets/menu/shoppingGuide.svg");
}
.icon-latest-activity {
  background-image: url("../../assets/menu/latestActivity.svg");
}
.icon-coupon {
  background-image: url("../../assets/menu/coupon.svg");
}
.icon-public-account {
  background-image: url("../../assets/menu/publicAccount.svg");
}
.icon-miniprogram {
  background-image: url("../../assets/menu/miniprogram.png");
}
.icon-custom-service {
  background-image: url("../../assets/menu/customService.svg");
}
.icon-limit-query {
  background-image: url("../../assets/menu/limitQuery.svg");
}

.article-header {
  padding-top: 8px;
  background-color: #eee;
}

.article-header h3 {
  background-color: #fff;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  color: #f30;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.article-more {
  border: 0;
  background-color: transparent;
  font-weight: 400;
}

.article-more:active {
  background-color: #eee;
}

.article-list {
  min-height: 500px;
  background-color: #eee;
}

.article-item {
  position: relative;
  display: block;
  padding: 15px 20px;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #eee;
}

.article-item:active {
  background-color: #eee;
}

.article-icon {
  display: block;
  overflow: hidden;
  border-radius: 2px;
}

.article-icon img {
  overflow: hidden;
  border-radius: 2px;
}

.article-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: space-around;
  justify-content: space-evenly;
}

.article-date {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 12px;
  line-height: 20px;
  color: #999;
  text-align: right;
  background-color: #fff;
}

.article-name {
  font-size: 14px;
  text-align: left;
}

.article-tag {
  font-size: 12px;
  line-height: 20px;
  color: #999;
  text-align: left;
}

.footer {
  width: 100%;
  height: 60px;
  font-size: 14px;
  line-height: 60px;
  text-align: center;
  background-color: #eee;
  a {
    color: #999;
  }
}
</style>

<script>
import Vue from "vue";
import { Swipe, SwipeItem, Toast } from "vant";
import { Lazyload } from "vant";
import { getActivityArticleList } from "@/services/api";
import { isMobile } from "@/utils/index";

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);

export default {
  name: "Index",
  components: {},
  data() {
    return {
      menus: [
        {
          name: "网上商城",
          keyName: "商城",
          iconClass: "icon-online-shop",
          src: "./onlineShop.html",
          type: 1,
          backgroundColor: "#f20",
        },
        {
          name: "免税门店",
          keyName: "门店",
          iconClass: "icon-offline-shop",
          src: "./offlineShop.html",
          type: 1,
          backgroundColor: "#4caf50",
        },
        {
          name: "购物指南",
          keyName: "指南",
          iconClass: "icon-shopping-guide",
          src: "./shoppingGuide.html",
          type: 1,
          backgroundColor: "#009688",
        },
        {
          name: "活动优惠",
          keyName: "优惠",
          iconClass: "icon-latest-activity",
          src: "./activities.html",
          type: 1,
          backgroundColor: "#f80",
        },
        {
          name: "免税优惠券",
          keyName: "优惠券",
          iconClass: "icon-coupon",
          src: "./coupon.html",
          type: 2,
          backgroundColor: "#03a9f4",
        },
        {
          name: "免税星球",
          keyName: "星球",
          iconClass: "icon-miniprogram",
          src: "./knowledgeGraph.html",
          type: 1,
          backgroundColor: "#4caf50",
        },
        {
          name: "客服电话",
          keyName: "电话",
          iconClass: "icon-custom-service",
          src: "./customerService.html",
          type: 1,
          backgroundColor: "#f50",
        },
        {
          name: "更多查询",
          keyName: "查询",
          iconClass: "icon-limit-query",
          src: "./moreQuery.html",
          type: 1,
          page: "../../limitQuery",
          backgroundColor: "#00f",
        },
      ],
      articles: [],
      swiperWidth: 600,
      swiperHeight: 300,
    };
  },
  mounted() {
    document.getElementById("preloader").style.display = "none";
    this.initData();
  },
  methods: {
    initData: function() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      getActivityArticleList(
        null,
        (data) => {
          if (!Array.isArray(data.data)) {
            return;
          }
          const articles = [];
          data.data.forEach((item) => {
            const hasArticle = articles.some((compareItem) => {
              if (compareItem.biz_tag === item.biz_tag) {
                return true;
              }
              return false;
            });
            if (!hasArticle) {
              articles.push(item);
            }
          });
          this.articles = articles;
          Toast.clear();
        },
        () => {
          Toast.fail("加载失败");
        }
      );
    },
    openMenu: function(menu) {
      if (menu.type === 1) {
        window.location.href = menu.src;
        return;
      }
      if (isMobile() && menu.mobileSrc) {
        window.location.href = menu.mobileSrc;
        return;
      }
      window.location.href = menu.src;
    },
    /**
     * 打开网页
     * @param e
     */
    openArticle: function(index) {
      const article = this.articles[index];
      window.location.href = article.link;
    },
    gotoCdfCoupon: function() {
      window.location.href =
        "https://mp.weixin.qq.com/s/1fl-YapukJF-XJ7lp31TRg";
    },
    gotoShenmianCoupon: function() {
      window.location.href =
        "https://mp.weixin.qq.com/s/w-wa3EGNjiTXqzYl0BCIJA";
    },
    /**
     * 跳转活动优惠
     */
    gotoActivities: function() {
      window.location.href = "./activities.html";
    },
  },
};
</script>
