<script>
import Vue from 'vue'
import { Loading } from 'vant'
Vue.use(Loading)

export default {
  name: 'ProgressLoading',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div class="loading-wrapper" :class="visible ? 'show' : 'hide'">
    <van-loading color="#aaa" />
  </div>
</template>

<style lang="less" scoped>
.loading-wrapper {
  position: fixed;
  top: 48px;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  width: 100%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
}
.show {
  display: flex;
}
.hide {
  display: none;
}
</style>
